import React, { useEffect, useState } from "react";
import {
  Drawer,
  Box,
  List,
  ListItemText,
  ListItemIcon,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
  styled,
  Collapse,
  IconButton,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import SecurityIcon from "@mui/icons-material/Security";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { fetchMenu } from "../../redux/slices/menuSlice";
import ListItemButtonStyled from "./ListItemButton"; // Adjust the import path

// Icon mapping
const iconMap = {
  home: HomeIcon,
  donation_list: VolunteerActivismIcon,
  users: PeopleIcon,
  roles: SecurityIcon,
  permissions: VerifiedUserIcon,
  finance: CurrencyRupeeIcon, // Define this icon if you have one
  // Add other mappings as needed
};

// Styled Drawer component
const DrawerStyled = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    width: 240,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: 240,
      boxSizing: "border-box",
    },
  },
  [theme.breakpoints.down("sm")]: {
    width: 240,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: 240,
      boxSizing: "border-box",
    },
  },
}));

// Sidebar component
const Sidebar = ({ drawerOpen, handleDrawerToggle }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const loading = useSelector((state) => state.menu.loading);
  const error = useSelector((state) => state.menu.error);
  const fetchMenuItems = useSelector((state) => state.menu.items);

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [openItemId, setOpenItemId] = useState(null); // Track only one open item

  useEffect(() => {
    dispatch(fetchMenu());
  }, [dispatch]);

  useEffect(() => {
    // Close the drawer once the data is fetched
    if (!loading && !error) {
      setIsDrawerOpen(false);
    }
  }, [loading, error]);

  const renderIcon = (iconName) => {
    const IconComponent = iconMap[iconName];
    return IconComponent ? <IconComponent /> : null;
  };

  const handleClick = (id) => {
    setOpenItemId((prev) => (prev === id ? null : id)); // Toggle only one open item
  };

  const renderMenuItems = (items) => {
    return items.map((item) => {
      const isActive = location.pathname.slice(1) === item.route;
      const hasChildren = item.children && item.children.length > 0;
      const isOpen = openItemId === item.id;

      return (
        <React.Fragment key={item.id}>
          <ListItemButtonStyled
            component={Link}
            to={item.route ? `/${item.route}` : "#"}
            isActive={isActive}
            onClick={hasChildren ? () => handleClick(item.id) : undefined}
          >
            <ListItemIcon>{renderIcon(item.menu_icon)}</ListItemIcon>
            <ListItemText primary={item.menu} />
            {hasChildren && (
              <IconButton size="small">
                {isOpen ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            )}
          </ListItemButtonStyled>
          {hasChildren && (
            <Collapse in={isOpen}>
              <List disablePadding>{renderMenuItems(item.children)}</List>
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <DrawerStyled
      variant={
        useMediaQuery(useTheme().breakpoints.down("sm"))
          ? "temporary"
          : "permanent"
      }
      anchor="left"
      open={
        useMediaQuery(useTheme().breakpoints.down("sm"))
          ? drawerOpen
          : isDrawerOpen
      }
      onClose={
        useMediaQuery(useTheme().breakpoints.down("sm"))
          ? handleDrawerToggle
          : undefined
      }
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding={2}
        backgroundColor="background.paper"
        marginBottom={2}
      >
        <img
          src="/assets/img/logo.jpg"
          alt="Company Logo"
          style={{ width: "100px", height: "auto" }}
        />
      </Box>
      <List>
        {loading && <CircularProgress />}
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
        {!loading && !error && renderMenuItems(fetchMenuItems)}
      </List>
    </DrawerStyled>
  );
};

export default Sidebar;
