import React, { useEffect, useState } from "react";
import { Box, CssBaseline, styled } from "@mui/material";
import { Header, Footer } from "./common/CommonComponents";
import { usePageTitle } from "../contexts/PageTitleContext";
import Sidebar from "./common/Sidebar";

const Content = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  [theme.breakpoints.up("sm")]: {
    marginLeft: 240,
  },
}));

const Layout = ({ children }) => {
  const { pageTitle } = usePageTitle();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    document.title = pageTitle || "Default Title";
  }, [pageTitle]);

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <CssBaseline />

      {/* Header */}
      <Header handleDrawerToggle={handleDrawerToggle} title={pageTitle} />

      {/* Sidebar */}
      <Sidebar
        drawerOpen={drawerOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Content>{children}</Content>
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
