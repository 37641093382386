import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  styled,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Typography,
  Menu,
  MenuItem,
  Avatar,
  CircularProgress,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { logout } from "../../services/apiService";
import { useDispatch, useSelector } from "react-redux"; // Import useSelector
import { fetchLoggedInUser } from "../../redux/slices/authSlice";

// Styled AppBar component
const AppBarStyled = styled(AppBar)(({ theme }) => ({
  width: `calc(100% - ${240}px)`,
  marginLeft: 240,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginLeft: 0,
  },
}));

const FooterStyled = styled(Box)(({ theme }) => ({
  marginTop: "auto",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  textAlign: "center",
}));

// Header component
const Header = ({ handleDrawerToggle, title }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
  const navigate = useNavigate();

  // Retrieve user data from Redux store
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    // Fetch Loggedin User data
    if (!user) {
      dispatch(fetchLoggedInUser());
    }
  }, [dispatch, user]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileSettings = () => {
    navigate("/profile-settings"); // Adjust the path as needed
    handleMenuClose();
  };

  const handleLogout = () => {
    setLoading(true);
    setError("");
    logout()
      .then((response) => {
        console.log("Logged out successfully:", response.data);
        setLoading(false);
        localStorage.removeItem("token");
        navigate("/login"); // Adjust the path as needed
        handleMenuClose();
      })
      .catch((err) => {
        console.error("Logout error:", err);
        setError("An error occurred during logout. Please try again.");
        setLoading(false);
      });
  };

  return (
    <AppBarStyled position="sticky">
      <Toolbar>
        {isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Box flexGrow={1}>
          <Typography variant="h6">{title}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" marginRight={2}>
            <Avatar
              src={user?.profileImage || "/assets/img/profile.png"}
              alt={user?.name || "User"}
            />
            <Typography
              variant="body1"
              color="inherit"
              marginLeft={1}
              onClick={handleMenuOpen}
              style={{ cursor: "pointer" }} // Make the username clickable
            >
              {user?.name || "Guest"}
            </Typography>
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleProfileSettings}>
              Profile Settings
            </MenuItem>
            <MenuItem onClick={handleLogout} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : "Logout"}
              {error && (
                <Typography color="error" marginLeft={1}>
                  {error}
                </Typography>
              )}
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBarStyled>
  );
};

// Footer component
const Footer = () => {
  return (
    <FooterStyled>
      <Typography variant="body2" color="textSecondary">
        © {new Date().getFullYear()} Your Company Name. All rights reserved.
      </Typography>
    </FooterStyled>
  );
};

export { Header, Footer };
