// src/App.js
import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./App.css";
import Layout from "./components/Layout";
import PrivateRoute from "./components/PrivateRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Lazy load components
const LoginPage = lazy(() => import("./components/LoginPage"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const Users = lazy(() => import("./components/UserManagement/Users"));
const AddUser = lazy(() => import("./components/UserManagement/AddUser"));
const EditUser = lazy(() => import("./components/UserManagement/EditUser"));
const Roles = lazy(() => import("./components/UserManagement/Roles"));
const AddRole = lazy(() => import("./components/UserManagement/AddRole"));
const EditRole = lazy(() => import("./components/UserManagement/EditRole"));
const Permissions = lazy(() =>
  import("./components/UserManagement/Permissions")
);
const NotFoundPage = lazy(() => import("./components/NotFoundPage"));
const DonationList = lazy(() => import("./components/Donation/DonationList"));
const AddDonation = lazy(() => import("./components/Donation/AddDonation"));
const EditDonation = lazy(() => import("./components/Donation/EditDonation"));
const ViewDonation = lazy(() => import("./components/Donation/ViewDonation"));
const DonationListAsPerStage = lazy(() =>
  import("./components/Donation/DonationListAsPerStage")
);
const DailyReport = lazy(() =>
  import("./components/FinancialReport/DailyReport")
);
const WeeklyReport = lazy(() =>
  import("./components/FinancialReport/WeeklyReport")
);
const MonthlyReport = lazy(() =>
  import("./components/FinancialReport/MonthlyReport")
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    background: {
      paper: "#fff",
    },
  },
  spacing: 8,
});

// Loader component
const Loader = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <CircularProgress />
  </Box>
);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          {/* Protected Routes inside Layout */}
          <Route
            path="/*"
            element={
              <Layout>
                <Suspense fallback={<Loader />}>
                  <ToastContainer />
                  <Routes>
                    <Route
                      path="/dashboard"
                      element={
                        <PrivateRoute element={Dashboard} path={"dashboard"} />
                      }
                    />
                    <Route
                      path="/donation/donation-list"
                      element={<PrivateRoute element={DonationList} />}
                    />
                    <Route
                      path="/donation/add-donation"
                      element={<PrivateRoute element={AddDonation} />}
                    />

                    <Route
                      path="/donation/edit-donation/:id"
                      element={<PrivateRoute element={EditDonation} />}
                    />
                    <Route
                      path="/donation/view-donation/:id"
                      element={<PrivateRoute element={ViewDonation} />}
                    />
                    <Route
                      path="/donation/:stage"
                      element={
                        <PrivateRoute element={DonationListAsPerStage} />
                      }
                    />

                    <Route
                      path="/finance/daily-report"
                      element={<PrivateRoute element={DailyReport} />}
                    />
                    <Route
                      path="/finance/weekly-report"
                      element={<PrivateRoute element={WeeklyReport} />}
                    />
                    <Route
                      path="/finance/monthly-report"
                      element={<PrivateRoute element={MonthlyReport} />}
                    />
                    <Route
                      path="/users/staff"
                      element={<PrivateRoute element={Users} />}
                    />
                    <Route
                      path="/users/add-staff"
                      element={<PrivateRoute element={AddUser} />}
                    />
                    <Route
                      path="/users/edit-user/:id"
                      element={<PrivateRoute element={EditUser} />}
                    />
                    <Route
                      path="/roles"
                      element={<PrivateRoute element={Roles} />}
                    />
                    <Route
                      path="/roles/add-role"
                      element={<PrivateRoute element={AddRole} />}
                    />
                    <Route
                      path="/roles/edit-role/:id"
                      element={<PrivateRoute element={EditRole} />}
                    />
                    <Route
                      path="/permissions"
                      element={<PrivateRoute element={Permissions} />}
                    />
                    {/* Add a route to handle 404s, it should be the last route */}
                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>
                </Suspense>
              </Layout>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
