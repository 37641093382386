// src/slices/caFormSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { saveDonationCAData } from "../../services/apiService";

export const updateCAdetails = createAsyncThunk(
  "caForm/updateCAdetails",
  async ({ id, donorData }, { rejectWithValue }) => {
    console.log(donorData);
    try {
      const response = await saveDonationCAData(id, donorData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 422) {
        return rejectWithValue(error.response.data.errors);
      }
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const caFormSlice = createSlice({
  name: "caForm",
  initialState: {
    status: "idle",
    success: false,
    error: null,
  },
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCAdetails.pending, (state) => {
        state.status = "loading";
        state.success = false;
      })
      .addCase(updateCAdetails.fulfilled, (state) => {
        state.status = "succeeded";
        state.success = true;
      })
      .addCase(updateCAdetails.rejected, (state, action) => {
        state.status = "failed";
        state.success = false;
        state.error = action.payload;
      });
  },
});

export const { resetStatus } = caFormSlice.actions;
export default caFormSlice.reducer;
