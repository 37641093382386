import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPermissions,
  addPermission,
  updatePermission,
  deletePermission,
  getPermissionByRoleId,
} from "../../services/apiService";

export const fetchPermissions = createAsyncThunk(
  "permissions/fetchPermissions",
  async ({ page, pageSize, search }) => {
    const response = await getPermissions({ page, pageSize, search });
    // return response.data.data;
    return {
      data: response.data.data,
      totalCount: 19,
    };
  }
);

export const createPermission = createAsyncThunk(
  "permissions/createPermission",
  async (permission) => {
    const response = await addPermission(permission);
    return response.data.data;
  }
);

export const fetchPermissionByRoleId = createAsyncThunk(
  "roles/fetchRoleById",
  async (id) => {
    const response = await getPermissionByRoleId(id);
    return response.data.data;
  }
);

export const modifyPermission = createAsyncThunk(
  "permissions/modifyPermission",
  async ({ id, permission }) => {
    const response = await updatePermission(id, permission);
    return response.data.data;
  }
);

export const removePermission = createAsyncThunk(
  "permissions/removePermission",
  async (id) => {
    await deletePermission(id);
    return id;
  }
);

const permissionSlice = createSlice({
  name: "permissions",
  initialState: {
    loading: false,
    permissions: [],
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Users
      .addCase(fetchPermissions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.loading = false;
        state.permissions = action.payload.data;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchPermissionByRoleId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPermissionByRoleId.fulfilled, (state, action) => {
        state.loading = false;
        state.permissions = action.payload;
      })
      .addCase(fetchPermissionByRoleId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // .addCase(fetchPermissionByRoleId.fulfilled, (state, action) => {
      //   state.loading = false;
      //   const index = state.permissions.findIndex(
      //     (role) => role.id === action.payload.id
      //   );
      //   if (index !== -1) {
      //     state.permissions[index] = action.payload;
      //   }
      // })

      // Create User
      .addCase(createPermission.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPermission.fulfilled, (state, action) => {
        state.loading = false;
        state.permissions.push(action.payload);
      })
      .addCase(createPermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Modify User
      .addCase(modifyPermission.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(modifyPermission.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.permissions.findIndex(
          (role) => role.id === action.payload.id
        );
        if (index !== -1) {
          state.permissions[index] = action.payload;
        }
      })
      .addCase(modifyPermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Remove User
      .addCase(removePermission.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removePermission.fulfilled, (state, action) => {
        state.loading = false;
        state.permissions = state.permissions.filter(
          (role) => role.id !== action.payload
        );
      })
      .addCase(removePermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default permissionSlice.reducer;
