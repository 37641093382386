import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCategory,
  addCategory,
  updateCategory,
  deleteCategory,
  getCategoryById,
} from "../../services/apiService";

export const fetchCategories = createAsyncThunk(
  "category/fetchCategories",
  async () => {
    const response = await getCategory();
    return response.data.data;
  }
);

export const fetchCategoryById = createAsyncThunk(
  "category/fetchCategoryById",
  async (id) => {
    const response = await getCategoryById(id);
    return response.data.data;
  }
);

export const createCategory = createAsyncThunk(
  "category/createCategory",
  async (category, { rejectWithValue }) => {
    try {
      const response = await addCategory(category);
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 422) {
        return rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

export const modifyCategory = createAsyncThunk(
  "category/modifyCategory",
  async ({ id, category }) => {
    const response = await updateCategory(id, category);
    return response.data;
  }
);

export const removeCategory = createAsyncThunk(
  "category/removeCategory",
  async (id) => {
    await deleteCategory(id);
    return id;
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState: {
    loading: false,
    category: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Categories
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.category = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCategoryById.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.category.findIndex(
          (category) => category.id === action.payload.id
        );
        if (index !== -1) {
          state.category[index] = action.payload;
        }
      })

      // Create Category
      .addCase(createCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.category.push(action.payload);
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Modify Category
      .addCase(modifyCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(modifyCategory.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.category.findIndex(
          (category) => category.id === action.payload.id
        );
        if (index !== -1) {
          state.category[index] = action.payload;
        }
      })
      .addCase(modifyCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Remove Category
      .addCase(removeCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.category = state.category.filter(
          (category) => category.id !== action.payload
        );
      })
      .addCase(removeCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default categorySlice.reducer;
