import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRoles,
  addRole,
  updateRole,
  deleteRole,
  getRoleById,
  getAllMenu,
} from "../../services/apiService";

export const fetchRoles = createAsyncThunk("roles/fetchRoles", async () => {
  const response = await getRoles();
  return response.data.data;
});

export const fetchRoleById = createAsyncThunk(
  "roles/fetchRoleById",
  async (id) => {
    const response = await getRoleById(id);
    return response.data.data;
  }
);

export const createRole = createAsyncThunk(
  "roles/createRole",
  async (role, { rejectWithValue }) => {
    try {
      const response = await addRole(role);
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 422) {
        return rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

export const modifyRole = createAsyncThunk(
  "roles/modifyRole",
  async ({ id, role }) => {
    const response = await updateRole(id, role);
    return response.data;
  }
);

export const removeRole = createAsyncThunk("roles/removeRole", async (id) => {
  await deleteRole(id);
  return id;
});

export const fetchAllMenu = createAsyncThunk(
  "menu/fetchAllMenu",
  async (_, thunkAPI) => {
    try {
      const response = await getAllMenu();
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const roleSlice = createSlice({
  name: "roles",
  initialState: {
    loading: false,
    roles: [],
    AllMenu: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Roles
      .addCase(fetchRoles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.roles = action.payload;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchRoleById.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.roles.findIndex(
          (role) => role.id === action.payload.id
        );
        if (index !== -1) {
          state.roles[index] = action.payload;
        }
      })

      // Create Role
      .addCase(createRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.loading = false;
        state.roles.push(action.payload);
      })
      .addCase(createRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Modify Role
      .addCase(modifyRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(modifyRole.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.roles.findIndex(
          (role) => role.id === action.payload.id
        );
        if (index !== -1) {
          state.roles[index] = action.payload;
        }
      })
      .addCase(modifyRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Remove Role
      .addCase(removeRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeRole.fulfilled, (state, action) => {
        state.loading = false;
        state.roles = state.roles.filter((role) => role.id !== action.payload);
      })
      .addCase(removeRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Fetch All Menu
      .addCase(fetchAllMenu.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllMenu.fulfilled, (state, action) => {
        state.loading = false;
        state.AllMenu = action.payload; // Correctly set the AllMenu state
      })
      .addCase(fetchAllMenu.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default roleSlice.reducer;
