import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchDonationData,
  saveDonationData,
  getDonationById,
  updateDonationData,
  fetchDonationDataAsPerStage,
  deleteDonationWithReason,
  approveDonationWithReason,
  rejectDonationWithReason,
} from "../../services/apiService";

// Async thunk to fetch donor details
export const fetchDonation = createAsyncThunk(
  "donation/fetchDonation",
  async (donationId, { rejectWithValue }) => {
    try {
      const response = await fetchDonationData();
      return response.data.data || [];
    } catch (error) {
      if (error.response && error.response.status === 422) {
        return rejectWithValue(error.response.data.errors);
      }
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Async thunk to fetch donor details
export const fetchDonationAsPerStage = createAsyncThunk(
  "donation/fetchDonationAsPerStage",
  async (stage, { rejectWithValue }) => {
    try {
      const response = await fetchDonationDataAsPerStage(stage);
      return response.data.data || [];
    } catch (error) {
      if (error.response && error.response.status === 422) {
        return rejectWithValue(error.response.data.errors);
      }
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Async thunk to save new donor details
export const saveDonorDetails = createAsyncThunk(
  "donation/saveDonorDetails",
  async (donorData, { rejectWithValue }) => {
    try {
      const response = await saveDonationData(donorData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 422) {
        return rejectWithValue(error.response.data.errors);
      }
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Async thunk to fetch donation details by ID
export const fetchDonationDetailsById = createAsyncThunk(
  "donation/fetchDonationDetailsById",
  async (donationId, { rejectWithValue }) => {
    try {
      const response = await getDonationById(donationId);
      console.log(response.data.data);
      return response.data.data; // Assuming the data you need is inside response.data.data
    } catch (error) {
      if (error.response && error.response.status === 422) {
        return rejectWithValue(error.response.data.errors);
      }
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Async thunk to update existing donation details
export const updateDonorDetails = createAsyncThunk(
  "donation/updateDonorDetails",
  async ({ id, donorData }, { rejectWithValue }) => {
    try {
      const response = await updateDonationData(id, donorData);
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 422) {
        return rejectWithValue(error.response.data.data);
      }
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const approveDonation = createAsyncThunk(
  "donation/approveDonation",
  async ({ id, reason }) => {
    await approveDonationWithReason(id, reason);
    return id;
  }
);

export const rejectDonation = createAsyncThunk(
  "donation/rejectDonation",
  async ({ id, reason }) => {
    await rejectDonationWithReason(id, reason);
    return id;
  }
);
export const deleteDonation = createAsyncThunk(
  "donation/deleteDonation",
  async ({ id, reason }) => {
    await deleteDonationWithReason(id, reason);
    return id;
  }
);

const donationSlice = createSlice({
  name: "donation",
  initialState: {
    loading: false,
    error: null,
    success: false,
    validationErrors: {}, // Store validation errors here
    donationDetails: null, // Store fetched donation details here
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
      state.validationErrors = {}; // Clear validation errors
    },
    clearSuccess: (state) => {
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDonation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.donationDetails = null; // Clear previous donation details
      })
      .addCase(fetchDonation.fulfilled, (state, action) => {
        state.loading = false;
        state.donationDetails = action.payload; // Store fetched donation details
      })
      .addCase(fetchDonation.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.validationErrors = action.payload; // Store validation errors
        } else {
          state.error = action.error.message;
        }
      })
      //Fetch Donation as per Stage
      .addCase(fetchDonationAsPerStage.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.donationDetails = null; // Clear previous donation details
      })
      .addCase(fetchDonationAsPerStage.fulfilled, (state, action) => {
        state.loading = false;
        state.donationDetails = action.payload; // Store fetched donation details
      })
      .addCase(fetchDonationAsPerStage.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.validationErrors = action.payload; // Store validation errors
        } else {
          state.error = action.error.message;
        }
      })
      // Handle saveDonorDetails thunk
      .addCase(saveDonorDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
        state.validationErrors = {}; // Clear old validation errors
      })
      .addCase(saveDonorDetails.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(saveDonorDetails.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.validationErrors = action.payload; // Store validation errors
        } else {
          state.error = action.error.message;
        }
      })

      // Handle fetchDonationDetailsById thunk
      .addCase(fetchDonationDetailsById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.donationDetails = null; // Clear previous donation details
      })
      .addCase(fetchDonationDetailsById.fulfilled, (state, action) => {
        state.loading = false;
        state.donationDetails = action.payload; // Store fetched donation details
      })
      .addCase(fetchDonationDetailsById.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.validationErrors = action.payload; // Store validation errors
        } else {
          state.error = action.error.message;
        }
      })
      // Handle updateDonorDetails thunk
      .addCase(updateDonorDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.validationErrors = {}; // Clear old validation errors
      })
      .addCase(updateDonorDetails.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(updateDonorDetails.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.validationErrors = action.payload; // Store validation errors
        } else {
          state.error = action.error.message;
        }
      });
  },
});

export const { clearError, clearSuccess } = donationSlice.actions;
export default donationSlice.reducer;
