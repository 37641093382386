// import React from "react";
// import { Navigate, useLocation } from "react-router-dom";

// const PrivateRoute = ({ element: Element, ...rest }) => {
//   const token = localStorage.getItem("token"); // Check if token exists
//   const location = useLocation();

//   return token ? (
//     <Element {...rest} />
//   ) : (
//     <Navigate to="/" state={{ from: location }} />
//   );
// };

// export default PrivateRoute;

import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ element: Element, path, ...rest }) => {
  const token = localStorage.getItem("token"); // Check if token exists
  const location = useLocation();
  const userRoles = useSelector((state) => state.auth.user?.role); // Adjust path as needed
  //console.log(userRoles);
  //console.log(path);
  const requiredRoles = "";
  // Check if the user has the required roles
  const hasAccess = requiredRoles
    ? requiredRoles.some((role) => userRoles.includes(role))
    : true;

  return token && hasAccess ? (
    <Element {...rest} />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  );
};

export default PrivateRoute;
