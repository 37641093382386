import { styled } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";
import tinycolor from "tinycolor2";

// Define your base color
const BASE_COLOR = "#1976d2";

// Generate a lighter color
const LIGHTER_ACTIVE_COLOR = tinycolor(BASE_COLOR).lighten(30).toString(); // Adjust the percentage as needed

// Styled active link with prop filtering
const ListItemButtonStyled = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ theme, isActive }) => ({
  ...(isActive && {
    backgroundColor: LIGHTER_ACTIVE_COLOR,
    "&:hover": {
      backgroundColor: LIGHTER_ACTIVE_COLOR,
    },
  }),
}));

export default ListItemButtonStyled;
