// src/redux/slices/authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchMenu } from "./menuSlice";
import axiosInstance from "../../Utils/axiosInstance";
import { getLoggedInUser } from "../../services/apiService";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/login", { email, password });
      if (response.data.success) {
        const { token, name, role_id, role } = response.data.data;
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", token);
        dispatch(fetchMenu()); // Fetch menu after successful login
        return { token, name, role_id, role }; // Return the user data
      } else {
        return rejectWithValue(response.data.message || "Login failed");
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const fetchLoggedInUser = createAsyncThunk(
  "users/LoggedInUser",
  async () => {
    const response = await getLoggedInUser();
    return response.data.data;
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        // Update state.user with the data from action.payload
        state.user = {
          name: action.payload.name,
          role_id: action.payload.role_id,
          role: action.payload.role,
        };
        state.loading = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = action.payload.message || action.error.message;
        state.loading = false;
      })
      //LoggedinUser
      .addCase(fetchLoggedInUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLoggedInUser.fulfilled, (state, action) => {
        state.user = {
          name: action.payload.name,
          role_id: action.payload.role_id,
          role: action.payload.role,
          role_code: action.payload.role_code,
        };
        state.loading = false;
      })
      .addCase(fetchLoggedInUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default authSlice.reducer;
