// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import roleReducer from "./slices/roleSlice";
import permissionReducer from "./slices/permissionSlice";
import authReducer from "./slices/authSlice";
import menuReducer from "./slices/menuSlice";
import donationReducer from "./slices/donationSlice";
import categoryReducer from "./slices/categorySlice";
import caFormReducer from "./slices/caFormSlice";
// Configure the store with the persistedReducer
export default configureStore({
  reducer: {
    users: userReducer,
    roles: roleReducer,
    permissions: permissionReducer,
    auth: authReducer,
    menu: menuReducer,
    donation: donationReducer,
    category: categoryReducer,
    caForm: caFormReducer,
  },
});
