import apiClient from "../Utils/apiClient";

//permissionApi
export const fetchPermissionForRoute = (menuId) =>
  apiClient.get(`/checkAccessPermission/${menuId}`);
//Dashboard
export const dashboardChart = () => apiClient.get("/dashboardChart");

//Financial
export const fetchTodaysTransactions = () =>
  apiClient.get("/fetchTodaysTransactions");
export const fetchWeeklyTransactions = ({ startDate, endDate }) =>
  apiClient.get("/fetchWeeklyTransactions", {
    params: { startDate, endDate },
  });
export const fetchMonthlyTransactions = ({ startDate, endDate }) =>
  apiClient.get("/fetchMonthlyTransactions", {
    params: { startDate, endDate },
  });
// User API
export const getUsers = () => apiClient.get("/users");
export const addUser = (user) => apiClient.post("/users", user);
export const getUserById = (id) => apiClient.get(`/users/${id}`);
export const updateUser = (id, user) => apiClient.put(`/users/${id}`, user);
export const deleteUser = (id) => apiClient.delete(`/users/${id}`);
export const getLoggedInUser = () => apiClient.get("/LoggedInusers");
// Role API
export const getRoles = () => apiClient.get("/roles");
export const addRole = (role) => apiClient.post("/roles", role);
export const getRoleById = (id) => apiClient.get(`/roles/${id}`);
export const updateRole = (id, role) => apiClient.put(`/roles/${id}`, role);
export const deleteRole = (id) => apiClient.delete(`/roles/${id}`);

// Permission API
export const getPermissions = ({ page, pageSize, search }) =>
  apiClient.get("/permissions", {
    params: {
      page,
      limit: pageSize,
      search,
    },
  });
export const addPermission = (permission) =>
  apiClient.post("/permissions", permission);
export const getPermissionByRoleId = (id) =>
  apiClient.get(`/permissions/${id}`);
export const updatePermission = (id, permission) =>
  apiClient.put(`/permissions/${id}`, permission);
export const deletePermission = (id) => apiClient.delete(`/permissions/${id}`);

// Get Menu API
export const getMenuList = () => apiClient.get("/getMenuList");
export const getAllMenu = () => apiClient.get("/getAllMenu");

// Logout
export const logout = () => apiClient.get("/logout");

// Category API
export const getCategory = () => apiClient.get("/categories");
export const addCategory = (category) =>
  apiClient.post("/categories", category);
export const getCategoryById = (id) => apiClient.get(`/categories/${id}`);
export const updateCategory = (id, category) =>
  apiClient.put(`/categories/${id}`, category);
export const deleteCategory = (id) => apiClient.delete(`/categories/${id}`);

// Donation API
export const fetchDonationData = () => apiClient.get("/donations");
export const fetchDonationDataAsPerStage = (stage) =>
  apiClient.get(`/donationasperstage/${stage}`);
export const saveDonationData = (donationData) =>
  apiClient.post("/donations", donationData);
export const getDonationById = (id) => apiClient.get(`/donations/${id}`);
export const updateDonationData = (id, donorData) =>
  apiClient.post(`/donations/${id}`, donorData);
export const deleteDonationWithReason = (id, donorData) =>
  apiClient.post(`/deleteDonation/${id}`, donorData);
export const approveDonationWithReason = (id, donorData) =>
  apiClient.post(`/approveDonation/${id}`, donorData);
export const rejectDonationWithReason = (id, donorData) =>
  apiClient.post(`/rejectDonation/${id}`, donorData);
export const saveDonationCAData = (id, donorData) =>
  apiClient.post(`/saveDonationCAData/${id}`, donorData);
